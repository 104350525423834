import { template as template_5ff58b45c9514fdca4299f1b8e004a39 } from "@ember/template-compiler";
const FKLabel = template_5ff58b45c9514fdca4299f1b8e004a39(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
