import { template as template_43ec79c41c34483c9c4ca14cd4aeffd0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_43ec79c41c34483c9c4ca14cd4aeffd0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
